import { NetworkType } from '@prisma/client';

export const NETWORK_COOKIE_KEY = 'PREFERRED_NETWORK';

export function setNetwork(network: NetworkType, days: number): void {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${NETWORK_COOKIE_KEY}=${encodeURIComponent(network)};expires=${expires};path=/`;
}

export function getNetworkType(): string | undefined {
  if (typeof window === 'undefined') return undefined;

  const cookies = document.cookie.split('; ').reduce(
    (acc, cookie) => {
      const [key, val] = cookie.split('=');
      acc[key] = decodeURIComponent(val);
      return acc;
    },
    {} as Record<string, string>,
  );

  return cookies[NETWORK_COOKIE_KEY];
}
