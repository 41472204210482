import { createConfig, RouteOptions as LifiRouteOptions } from '@lifi/sdk';
import { getSlippage } from './user-settings';

export const lifiConfig = createConfig({
  integrator: 'MetaBot',
  apiKey:
    '2e6c5f1d-7e6a-4040-a1bd-f6b0546000cb.ee764240-73e3-478e-9e86-789b932d1d94',
});

/**
 * {"fromChainId":1,"fromAmount":"1000000000000000000","fromTokenAddress":"0x0000000000000000000000000000000000000000","toChainId":1,"toTokenAddress":"0x6B175474E89094C44Da98b954EedeAC495271d0F","options":{"integrator":"jumper.exchange","order":"RECOMMENDED","slippage":0.005,"maxPriceImpact":0.4,"allowSwitchChain":false,"bridges":{"allow":["hop","cbridge","optimism","hyphen","gnosis","stargate","arbitrum","celercircle","across","allbridge","celerim","polygon","amarok"]},"exchanges":{"allow":["1inch","openocean","0x","uniswap","sushiswap","apeswap","quickswap","honeyswap","lif3swap","pancakeswap","swapr","spookyswap","spiritswap","soulswap","tombswap","viperswap","pangolin","arbswap","solarbeam","okcswap","cronaswap","vvsfinance","stellaswap","beamswap","voltage","ubeswap","oolongswap","diffusion","cronus","evmoswap","trisolaris","wagyuswap","stable","dodo","verse","kyberswap"]},"insurance":false}}
 */

// export const routeOptions: RouteOptions = {
//   slippage: 1 / 100, // 1%
//   order: 'RECOMMENDED',
//   // fee: 1 / 100, // 1%
//   allowSwitchChain: false,
//   integrator: 'MetaBot',
//   // maxPriceImpact: 0.4,
//   bridges: {
//     allow: [
//       'hop',
//       'cbridge',
//       'optimism',
//       'hyphen',
//       'gnosis',
//       'stargate',
//       'arbitrum',
//       'celercircle',
//       'across',
//       'allbridge',
//       'celerim',
//       'polygon',
//       'amarok'
//     ]
//   },
//   exchanges: {
//     allow: [
//       '1inch',
//       'openocean',
//       '0x',
//       'uniswap',
//       'sushiswap',
//       'apeswap',
//       'quickswap',
//       'honeyswap',
//       'lif3swap',
//       'pancakeswap',
//       'swapr',
//       'spookyswap',
//       'spiritswap',
//       'soulswap',
//       'tombswap',
//       'viperswap',
//       'pangolin',
//       'arbswap',
//       'solarbeam',
//       'okcswap',
//       'cronaswap',
//       'vvsfinance',
//       'stellaswap',
//       'beamswap',
//       'voltage',
//       'ubeswap',
//       'oolongswap',
//       'diffusion',
//       'cronus',
//       'evmoswap',
//       'trisolaris',
//       'wagyuswap',
//       'stable',
//       'dodo',
//       'verse',
//       'kyberswap'
//     ]
//   },
//   insurance: false
// }

export type RouteOptions = {
  lifi: LifiRouteOptions;
  oneInch: { slippage: number };
  solana: { slippage: number };
};
export const getRouteOptions = async (): Promise<RouteOptions> => {
  const slippage = await getSlippage();
  if (!slippage) {
    throw new Error('failed to fetch slippage');
  }
  return {
    lifi: {
      order: 'RECOMMENDED',
      slippage: slippage / 100,
      allowSwitchChain: false,
    },
    oneInch: { slippage: slippage },
    solana: { slippage: slippage * 100 },
  };
};
