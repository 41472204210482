'use client';

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { ChainId } from '../constants';
import { setNetwork } from '../network';
import { NetworkType } from '@prisma/client';
import { setChainId } from '../user';

interface ChainIdContextProps {
  chainId: number;
  setChainId: (chainId: number) => void;
  isLoading: boolean;
}

const ChainIdContext = createContext<ChainIdContextProps | undefined>(
  undefined,
);

export function ChainIdProvider({
  children,
  chainId,
}: {
  children: ReactNode;
  chainId: number;
}) {
  const [chainIdState, setChainIdState] = useState<number>(chainId);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setNetwork(
      chainId == ChainId.solana ? NetworkType.Solana : NetworkType.Evm,
      100,
    );
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChainIdChange = (chainId: number) => {
    setChainIdState(chainId);
    setChainId(chainId);
    setNetwork(
      chainId == ChainId.solana ? NetworkType.Solana : NetworkType.Evm,
      100,
    );
  };

  return (
    <ChainIdContext.Provider
      value={{
        chainId: chainIdState,
        setChainId: handleChainIdChange,
        isLoading,
      }}
    >
      {children}
    </ChainIdContext.Provider>
  );
}

export function useChainId() {
  const context = useContext(ChainIdContext);
  if (!context) {
    throw new Error('useChainId must be used within a ChainIdProvider');
  }
  return context as ChainIdContextProps;
}
