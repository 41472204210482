import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Bricolage_Grotesque\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-heading\"}],\"variableName\":\"fontHeading\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Space_Mono\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-body\"}],\"variableName\":\"fontBody\"}");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
