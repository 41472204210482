'use client';

import { useClerk, useSession } from '@clerk/nextjs';
import { IProvider } from '@web3auth/base';
import { Web3Auth, decodeToken } from '@web3auth/single-factor-auth';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { web3AuthConfig } from '../services/web3auth';
import { solana } from '../constants';

type Context = {
  switchChain: (chainId: number) => Promise<void>;
  w3aProvider: IProvider | null;
  web3auth: Web3Auth | null;
  logout: () => void;
};

const Web3AuthContext = createContext<Context>({
  switchChain: async () => {
    throw new Error('not implemented');
  },
  w3aProvider: null,
  web3auth: null,
  logout: () => {
    throw new Error('not implemented');
  },
});

export function Web3AuthProvider({ children }: PropsWithChildren) {
  const [w3aProvider, setW3aProvider] = useState<IProvider | null>(null);
  const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
  const { session } = useSession();
  const { signOut } = useClerk();
  const switchChain = useCallback(
    async (chainId: number) => {
      if (!web3auth || chainId == solana.id) return;
      await web3auth.switchChain({ chainId: '0x' + chainId.toString(16) });
    },
    [web3auth],
  );

  const logout = () => {
    signOut();
    web3auth?.logout();
  };

  useEffect(() => {
    const init = async () => {
      try {
        // TODO: usage of mainnet
        const web3auth = new Web3Auth(web3AuthConfig);
        await web3auth.init();
        setWeb3auth(web3auth);

        if (!web3auth.provider) {
          throw new Error('No provider found');
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const connect = async () => {
      if (!web3auth || !session) {
        return;
      }
      if (!web3auth.connected) {
        const sessionToken = (await session.getToken()) || '';
        const { payload } = decodeToken(sessionToken);
        const web3authProvider = await web3auth.connect({
          verifier: 'aggregated-auths',
          verifierId: (payload as any).sub,
          idToken: sessionToken,
          subVerifierInfoArray: [{ verifier: 'clerk', idToken: sessionToken }],
        });
        setW3aProvider(web3authProvider);
      } else {
        const provider = web3auth.provider;
        if (provider) {
          setW3aProvider(provider);
        }
      }
    };

    if (web3auth && session) connect();
  }, [web3auth, session]);

  return (
    <Web3AuthContext.Provider
      value={{ switchChain, w3aProvider, web3auth, logout }}
    >
      {children}
    </Web3AuthContext.Provider>
  );
}

export const useWeb3Auth = () => useContext(Web3AuthContext);
