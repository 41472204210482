import { env } from '@/env';
import {
  CHAIN_NAMESPACES,
  CustomChainConfig,
  IProvider,
  WEB3AUTH_NETWORK,
} from '@web3auth/base';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import {
  SolanaPrivateKeyProvider,
  SolanaWallet,
} from '@web3auth/solana-provider';
import { Address, Hex, Chain as ViemChain, WalletClient } from 'viem';
import { Web3Auth as Web3AuthNode } from '@web3auth/node-sdk';
import { ChainId, solana, supportedChains } from '../constants';
import { createWalletClient, http } from 'viem';
import { privateKeyToAccount } from 'viem/accounts';
import { config, EVM } from '@lifi/sdk';
import { lifiConfig } from '../route';
export const web3AuthClientId = env.NEXT_PUBLIC_WEB3_AUTH_CLIENT_ID;

export function viemToWeb3AuthChain(chain: ViemChain): CustomChainConfig {
  return {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x' + chain.id.toString(16),
    rpcTarget: chain.rpcUrls.default.http[0],
    displayName: chain.name,
    blockExplorerUrl: chain.blockExplorers?.default.url,
    ticker: chain.nativeCurrency.name,
    tickerName: chain.name,
  };
}

const supportedEvmChains = supportedChains.filter(e => e.id != solana.id);
export const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig: viemToWeb3AuthChain(supportedEvmChains[0]) },
});

supportedEvmChains
  .slice(1)
  .map(viemToWeb3AuthChain)
  .forEach(e => privateKeyProvider.addChain(e));

export const web3AuthConfig = {
  clientId: web3AuthClientId,
  privateKeyProvider: privateKeyProvider,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
  usePnPKey: false,
};

export const solanaPrivateKeyProvider = new SolanaPrivateKeyProvider({
  config: {
    chainConfig: {
      chainNamespace: CHAIN_NAMESPACES.SOLANA,
      chainId: '0x1',
      rpcTarget:
        env.NEXT_PUBLIC_SOLANA_RPC_URL || 'https://api.mainnet-beta.solana.com',
      displayName: 'Solana Mainnet',
      blockExplorerUrl: 'https://explorer.solana.com/',
      ticker: 'SOL',
      tickerName: 'Solana',
      logo: '',
    },
  },
});
export type VerifierType = 'telegram' | 'clerk';
export async function getAuthenticatedWeb3AuthNodeProvider(
  sub: string,
  token: string,
  verifier: VerifierType,
) {
  const web3auth = new Web3AuthNode(web3AuthConfig);

  await web3auth.init({
    provider: privateKeyProvider,
  });
  const w3aProvider = await web3auth.connect({
    verifier: 'aggregated-auths',
    verifierId: sub,
    idToken: token,
    subVerifierInfoArray: [{ idToken: token, verifier }],
  });

  if (!w3aProvider) {
    throw new Error('Failed to connect to Web3Auth');
  }

  return w3aProvider;
}

export async function fetchProviderWallet(
  w3aProvider: IProvider,
  chainId: number,
) {
  const privateKey = (await w3aProvider.request({
    method: 'eth_private_key',
  })) as string;
  const { getED25519Key } = await import('@toruslabs/openlogin-ed25519');
  const ed25519key = getED25519Key(privateKey).sk;

  let newWallet: WalletClient | SolanaWallet | null,
    newAddress: Address | string | null;
  const privKeyHex: Hex = `0x${Buffer.from(ed25519key.subarray(0, 32)).toString('hex')}`;
  const account = privateKeyToAccount(privKeyHex);

  const client = createWalletClient({
    account,
    chain: supportedChains.find(chain => chain.id == chainId),
    transport: http(),
  });
  switch (chainId) {
    case ChainId.ethereum:
    case ChainId.polygon:
    case ChainId.arbitrum:
    case ChainId.base:
    case ChainId.bsc:
    case ChainId.avalanche: {
      await privateKeyProvider.setupProvider(privateKey);
      newWallet = client;
      newAddress = (await client.getAddresses())[0];

      config.set({
        ...lifiConfig,
        providers: [
          EVM({
            getWalletClient: async () => client,
            switchChain: async chainId =>
              // Switch chain by creating a new wallet client
              createWalletClient({
                account,
                chain: supportedChains.find(chain => chain.id == chainId),
                transport: http(),
              }),
          }),
        ],
      });
      break;
    }
    case ChainId.solana: {
      await solanaPrivateKeyProvider.setupProvider(ed25519key.toString('hex'));
      const solanaWallet = new SolanaWallet(
        solanaPrivateKeyProvider as SolanaPrivateKeyProvider,
      );
      newWallet = solanaWallet;
      newAddress = (await solanaWallet.requestAccounts())[0] as `0x${string}`;
      break;
    }
    default:
      newWallet = null;
      newAddress = null;
  }
  return { newWallet, newAddress };
}
