'use client';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

const AiLoadingContext = createContext({
  isLoading: false,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLoading: (state: boolean) => {},
});

export function AILoadingProvider(props: PropsWithChildren) {
  const [isLoading, setLoading] = useState(false);
  return (
    <AiLoadingContext.Provider value={{ isLoading, setLoading }}>
      {props.children}
    </AiLoadingContext.Provider>
  );
}

export const useAILoading = () => useContext(AiLoadingContext);
