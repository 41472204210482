'use client';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';
import { TooltipProvider } from '@/components/ui/tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AILoadingProvider } from '@/lib/context/ai-loading-provider';
import { Web3AuthProvider } from '@/lib/context/web3-auth-provider';
import { ClerkProvider } from '@clerk/nextjs';
import { ChainIdProvider } from '@/lib/context/chain-id-provider';
import dynamic from 'next/dynamic';
import { env } from '@/env';

const MoonPayProvider = dynamic(
  () => import('@moonpay/moonpay-react').then(mod => mod.MoonPayProvider),
  {
    ssr: false,
  },
);

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (typeof window === 'undefined') {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export function Providers({
  children,
  chainId,
  ...props
}: ThemeProviderProps & { chainId: number }) {
  const queryClient = getQueryClient();

  return (
    <NextThemesProvider {...props}>
      <TooltipProvider>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <AILoadingProvider>
            <ChainIdProvider chainId={chainId}>
              <ClerkProvider>
                <Web3AuthProvider>
                  <MoonPayProvider apiKey={env.NEXT_PUBLIC_MOONPAY_API_KEY}>
                    {children}
                  </MoonPayProvider>
                </Web3AuthProvider>
              </ClerkProvider>
            </ChainIdProvider>
          </AILoadingProvider>
        </QueryClientProvider>
      </TooltipProvider>
    </NextThemesProvider>
  );
}
